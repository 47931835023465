const $document = $(document)
const showLeadMagnet = ({ uid, linkId = 'default' }) => $document.trigger(`leadMagnetShow:${uid}`, { linkId })

$document.on('click', '[data-leadmagnet-uid]', (e) => {
  const uid = e.currentTarget.getAttribute('data-leadmagnet-uid')
  const linkId = e.currentTarget.getAttribute('data-link-id')

  e.preventDefault()

  showLeadMagnet({ uid, linkId })
})
