import { Component } from 'preact'
import cx from 'classnames'
import Cookies from 'js-cookie'

import { SlideInLayer } from '../slideInLayer'
import { Cols } from '../cols'
import { Heading3, TextNode } from '../textNodes'
import EmailForm from '../emailForm'
import { setTypeToUrl, removeTypeFromUrl, getTypeFromUrl } from '../newPaybar/typesUrlHelper'
import { subscribeUser } from '../subscriptionForm/emailUtils'
import { typograf } from 'lib/textHelper'
import { verifySubscription } from './leadMagnetUtils'
import reachGoal from 'lib/reachGoal'

const DEFAULT_MAILING_LIST = 'default'
const FILE_TYPES = /\.(jpg|jpeg|png|bmp|gif|pdf)$/

const CONTACT_FORM_PREFIX = 'contactForm'
const LINK_DELIMETER = ':'
const DEFAULT_LINK_ID = 'default'

const capitalize = str => str ? str.charAt(0).toUpperCase() + str.slice(1) : ''

const toType = ({ id, linkId }) => {
  return [
    CONTACT_FORM_PREFIX,
    capitalize(id),
    (!linkId || linkId === DEFAULT_LINK_ID) ? null : `${LINK_DELIMETER}${linkId}`,
  ].filter(Boolean).join('')
}

const extractCurrentIdAndLinkId = (type) => {
  const [currentType, currentLinkId] = (type || '').split(LINK_DELIMETER)

  return {
    id: currentType.replace(CONTACT_FORM_PREFIX, ''),
    linkId: currentLinkId || DEFAULT_LINK_ID,
  }
}

const toObfuscatedHref = ({ id, linkId }) => window.location.pathname + '?' + toType({ id, linkId })

export default class Leadbar extends Component {
  constructor(props) {
    super(props)

    const typeFromUrl = getTypeFromUrl()
    const { id: currentId, linkId: currentLinkId } = extractCurrentIdAndLinkId(typeFromUrl)
    const isDefault = this.props.isDefault && !currentId && !!typeFromUrl
    const isSetInUrl = currentId && capitalize(this.props.id) === capitalize(currentId) && !!typeFromUrl

    this.state = {
      isVisible: isDefault || isSetInUrl,
      email: this.email,
      isSubscribed: false,
      currentLinkId,
    }

    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
    this.submit = this.submit.bind(this)
    this.reachGoal = this.reachGoal.bind(this)
  }

  componentDidMount() {
    this.hideLinks()
    this.revealIfSubscribed()
    this.setEventListener()

    setTimeout(this.focusInputIfVisible.bind(this), 200) // HACK: something forbids focus atm
  }

  componentDidUpdate() {
    this.revealIfSubscribed()
    this.focusInputIfVisible()
  }

  shouldComponentUpdate(_, nextState) {
    return this.state.isVisible !== nextState.isVisible ||
      this.state.isSubscribed !== nextState.isSubscribed ||
      this.state.email !== nextState.email
  }

  focusInputIfVisible() {
    if (this.state.isVisible) this.emailFormInput.focus()
  }

  get urlType() {
    return toType({ id: this.props.id, linkId: this.state.currentLinkId })
  }

  get meta() {
    return `&leadMagnetName=${this.props.name || ''}&leadMagnetHref=${this.href}&leadMagnetId=${this.id}`
  }

  get mailingList() {
    return this.props.mailingList || DEFAULT_MAILING_LIST
  }

  get href() {
    return this.props.hrefs[this.state.currentLinkId] || ''
  }

  get buttonText() {
    return this.props.buttonText || this.defaultButtonText
  }

  get defaultButtonText() {
    const isFile = FILE_TYPES.test(this.href)
    const suffix = isFile ? 'downloadFile' : 'openLink'

    return I18n.t(`leadMagnet.${suffix}`)
  }

  get idCapitalized() {
    if (!this.id) return ''

    return this.id.charAt(0).toUpperCase() + this.id.slice(1)
  }

  get email() {
    return (window.application.user?.email || Cookies.get('email') || '').toLowerCase()
  }

  get id() {
    return this.props.id || ''
  }

  get uid() {
    return this.props.uid
  }

  get protectedLinks() {
    return document.querySelectorAll(`[data-leadmagnet-uid="${this.uid}"][data-link-id]`)
  }

  setEventListener() {
    $(document).on(`leadMagnetShow:${this.uid}`, this.show)
  }

  hide() {
    removeTypeFromUrl()
    this.setState({ isVisible: false })
  }

  show(_, eventData) {
    const linkId = eventData?.linkId || 'default'

    this.setState({ isVisible: true, currentLinkId: linkId }, () => {
      setTypeToUrl(this.urlType)
    })
  }

  submit({ email }) {
    return subscribeUser({
      email,
      eventType: this.mailingList,
      eventId: this.id,
      additionalMeta: this.meta,
    })
      .then(isSubscribed => this.setState({ email, isSubscribed }))
      .then(this.reachGoal)
      .catch(e => {
        console.error(e) // eslint-disable-line no-console
        this.setState({ email })
      })
  }

  reachGoal() {
    const { goal } = this.props
    const ownGoal = [
      'LEAD_MAGNET',
      (this.id + '').toUpperCase(),
      'SUBSCRIBED',
    ].filter(Boolean).join('_')

    reachGoal(ownGoal)
    if (goal) reachGoal(goal)
  }

  redirect() {
    if (this.href) window.location = this.href
  }

  revealIfSubscribed() {
    if (!this.href) return null
    if (this.state.isSubscribed) return this.reveal()

    return this.fetchSubscriptionData()
      .then(res => this.setState({ isSubscribed: res.ok }, () => this.reveal()))
      .catch(e => console.error(e)) // eslint-disable-line no-console
  }

  reveal() {
    this.revealLinks()

    if (this.state.isVisible) {
      this.hide()
      this.redirect()
    }
  }

  fetchSubscriptionData() {
    const { email, mailingList, id: leadMagnetId } = this

    return verifySubscription({ email, mailingList, leadMagnetId })
  }

  hideLinks() {
    this.protectedLinks.forEach(a => {
      const linkId = a.getAttribute('data-link-id')
      const newHref = toObfuscatedHref({ id: this.id, linkId })

      a.setAttribute('data-original-href', a.getAttribute('href'))
      a.setAttribute('href', newHref)
    })
  }

  revealLinks() {
    this.protectedLinks.forEach(a => {
      const newHref = a.getAttribute('data-original-href')

      a.setAttribute('href', newHref)
      a.removeAttribute('data-leadmagnet-uid')
    })
  }

  render(props) {
    const { mod } = props

    return (
      <div className= { cx('leadbar', { is__visible: this.state.isVisible, [`is__${mod}`]: !!mod }) }>
        <SlideInLayer isVisible={ this.state.isVisible } setHidden={ this.hide }>
          <div className="module is__boxedDefault is__bleedBackgroundSides is__noMargins textBox">
            { props.name &&
              <Heading3>{ props.name }</Heading3>
            }

            <Cols transposeOnMobile className="is__desktopDivisionProportions6to10">
              <div className="leadbar-description">
                <TextNode>{ typograf(props.text) }</TextNode>
                { props.children }
              </div>
              <div className="leadbar-emailForm">
                <EmailForm
                  email={ this.state.email }
                  isSubscribed={ this.state.isSubscribed }
                  onSubmit={ this.submit }
                  onFormStateSubscribed={ this.props.href ? undefined : this.hide }
                  buttonText={ this.buttonText }
                  inputRef={ el => this.emailFormInput = el }
                />
              </div>
            </Cols>
          </div>
        </SlideInLayer>
      </div>
    )
  }
}
