import { Component } from 'preact'

const READ_BUTTON_TEXT = I18n.t('subscription.read')

export default class NewPaybarGlobalAccessButtons extends Component {
  constructor(props) {
    super(props)

    this.updateBookshelfBookLinks = this.updateBookshelfBookLinks.bind(this)
  }

  componentDidMount() {
    this.updateLinks()
  }

  componentDidUpdate() {
    this.updateLinks()
  }

  updateLinks() {
    if (!this.props.canRead) return
    if (!this.props.books || !this.props.books.length) return

    this.props.books.forEach(this.updateBookshelfBookLinks.bind(this))

    this.updateParticularBookLinks(this.props.books[0])
  }

  updateParticularBookLinks({ key }) {
    this.updateReadButton({ $el: $('.js__alternativeReadButton'), key })
    this.updateReadCover({ $el: $('.js__alternativeReadCover'), key })
  }

  updateBookshelfBookLinks({ key }) {
    this.updateReadButton({ $el: $(`.js__alternativeReadButton--${key}`), key })
    this.updateReadCover({ $el: $(`.js__alternativeReadCover--${key}`), key })
  }

  updateReadButton({ $el, key }) {
    $el
      .addClass('is__huge')
      .attr('href', `/books/${key}/`)
      .text(READ_BUTTON_TEXT)
      .removeAttr('data-goal')
      .removeAttr('data-leadmagnet-uid')
  }

  updateReadCover({ $el, key }) {
    $el
      .attr('href', `/books/${key}/`)
      .removeAttr('data-goal')
      .removeAttr('data-leadmagnet-uid')
  }

  render() {
    return (<div />)
  }
}
